import React, { useState, useLayoutEffect } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Layout from 'src/layouts/BaseLayout'
import FAQ from './sections/faq/_index'

// sections
import Hero from './sections/hero/_index'
import PoupancaMaisLimite from './sections/poupanca-mais-limite/_index'
import ConseguirLimiteComPoupanca from './sections/conseguir-limite-com-poupanca/_index'
import CDBMaisLimite from './sections/cdb-mais-limite/_index'
import AumentarLimiteComCDB from './sections/aumentar-limite-com-cdb/_index'
import PedirAumentoDeLimite from './sections/pedir-aumento-de-limite/_index'
import DicasParaAumentarSeuLimite from './sections/dicas-para-aumentar-seu-limite/_index'
import ConfiraMaisConteudos from './sections/confira-mais-conteudos/_index'

// Hooks

// Components

// PageContext
import pageContext from './pageContext.json'

// images

// Styles
import { Wrapper } from './style'

const CotacaoDolar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Layout pageContext={pageContext}>
      <Wrapper>
        <Hero
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <PoupancaMaisLimite
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <ConseguirLimiteComPoupanca />
        <CDBMaisLimite
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <AumentarLimiteComCDB />
        <PedirAumentoDeLimite
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <DicasParaAumentarSeuLimite />
        <ConfiraMaisConteudos
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <FAQ faq={pageContext.structuredData.faq} />
      </Wrapper>
    </Layout>
  )
}

export default CotacaoDolar
