import React, { useState, useLayoutEffect } from 'react'

import { Section, Link, Button } from './style'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

// Components
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QrCode from '../../assets/images/qrcode-hero.png'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;

}

const WIDTH_MD = 768

const Hero = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      element_action: 'click button',
      element_name: 'Aumentar meu Limite',
      section_name: 'Como aumentar o limite do cartão?',
    })
  }

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isStore={true}
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Abra sua conta digital e comece a acumular pontos com o cartão de crédito Inter'
        subtitle='Abra a câmera do celular e aponte para o <span class="fw-600">QR Code</span> abaixo para baixar o app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const oneLink = 'https://inter-co.onelink.me/Qyu7/4v9razol'

  return (
    <Section className='d-flex align-items-center'>
      {hubModal}
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 pr-md-0'>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-32 lh-lg-42 lh-xl-50 fw-600 text-grayscale--500'>
              <span className='d-xl-block'>Como aumentar o</span> limite do cartão?
            </h1>
            <h2 className='fs-20 fs-lg-24 lh-25 lh-lg-30 fw-600 text-orange--extra mt-3'>
              <span className='d-xl-block'>Tenha autonomia para aumentar o</span> <span className='d-xl-block'>limite do seu cartão sempre que</span> quiser!
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 fw-400 text-grayscale--500 mt-3'>
              Aplique na Poupança Mais Limite Extra ou no CDB Mais Limite e todo valor vira limite no seu cartão de crédito.
            </p>
            {
              isMobile ? (
                <Link
                  className='mt-4'
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_1',
                      element_action: 'click button',
                      element_name: 'Aumentar meu Limite',
                      section_name: 'Como aumentar o limite do cartão?',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Aumentar meu Limite
                </Link>
              ) : (
                <Button
                  onClick={handleButtonClick} title='Aumentar meu Limite' className='fs-14 lh-17 fw-600 rounded-2 border-0 text-none mt-md-4'
                >
                  Aumentar meu Limite
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
