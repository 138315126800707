import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QrCode from '../../assets/images/qrcode-cdb-mais-limite.png'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { Section, ImgSize, Link, Button } from './style'
import usePageQuery from '../../pageQuery'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
}

const WIDTH_MD = 768

const oneLink = 'https://inter-co.onelink.me/Qyu7/4v9razol'

const ComoPedirAumentoDeLimite = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  const data = usePageQuery()
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_6',
      element_action: 'click button',
      element_name: 'Investir no CDB Mais Limite',
      section_name: 'CDB Mais Limite',
    })
  }

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isStore={true}
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Abra sua conta digital e comece a acumular pontos com o cartão de crédito Inter'
        subtitle='Abra a câmera do celular e aponte para o <span class="fw-600">QR Code</span> abaixo para baixar o app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Section className='pb-md-0'>
      {hubModal}
      <div className='container'>
        <div className='row align-items-center'>
          <ImgSize className='col-12 col-md-6 col-lg-5'>
            <Img fluid={data.pedirAumentoDeLimite.fluid} alt='Tela de celular mostrando a função aumento de limite no Superapp ' />
          </ImgSize>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-42 lh-xl-50 fw-600 text-grayscale--500 mt-4 mt-md-0'>
              Como pedir aumento de limite no Inter?
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 fw-400 text-grayscale--500 mt-3'>
              Você também pode solicitar aumento de limite direto no Super App. É rápido e fácil!
            </p>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 fw-400 text-grayscale--500 mt-3'>
              A sua solicitação estará sujeita à análise.
            </p>
            {
              isMobile ? (
                <Link
                  className='mt-4'
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      Section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Investir no CDB Mais Limite',
                      section_name: 'CDB Mais Limite',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Investir no CDB Mais Limite
                </Link>
              ) : (
                <Button
                  onClick={handleButtonClick} title='Investir no CDB Mais Limite'
                  className='fs-14 lh-17 fw-600 rounded-2 border-0 text-none mt-md-4'
                >
                  Investir no CDB Mais Limite
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoPedirAumentoDeLimite
